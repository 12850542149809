/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

import banner from "~assets/images/privacy-banner.jpg";

class PrivacyPageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;
    
    appContext.setMenuActive(false);
    appContext.setNavText(`Back`);
  }

  //

  render() {
    return (
      <>
        <SEO pageTitle="Privacy" pathName="/privacy" />

        <Layout className="privacy-page w-full bg-sun-yellow gpu">
          <section
            className="privacy-page__banner w-full relative"
            style={{
              backgroundImage: `url(${banner})`
            }}
          >
            <article
              className="privacy-page__banner__inner animation-appear w-3/4 top-0 left-0 absolute px-6 xs:px-3 pt-2 xs:py-8"
              style={{ animationDelay: `100ms` }}
            >
              <h1 className="privacy-page__banner__text w-full f1 font-medium text-sun-yellow">
                Privacy
              </h1>
            </article>
          </section>

          <section
            className="animation-appear relative px-6 xs:px-3 pt-4 pb-24"
            style={{ animationDelay: `200ms` }}
          >
            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              Effective date: November 29, 2018 It’s All Fluff PTY LTD (“us”,
              “we”, or “our”) operates the www.itsallfluff.com website (the
              “Service”). This page informs you of our policies regarding the
              collection, use, and disclosure of personal data when you use our
              Service and the choices you have associated with that data. Our
              Privacy Policy for It’s All Fluff PTY LTD is managed through Free
              Privacy Policy Website. We use your data to provide and improve
              the Service. By using the Service, you agree to the collection and
              use of information in accordance with this policy. Unless
              otherwise defined in this Privacy Policy, terms used in this
              Privacy Policy have the same meanings as in our Terms and
              Conditions, accessible from www.itsallfluff.com
            </p>

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Information Collection And Use?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              We collect several different types of information for various
              purposes to provide and improve our Service to you. Types of Data
              Collected Personal Data While using our Service, we may ask you to
              provide us with certain personally identifiable information that
              can be used to contact or identify you (“Personal Data”).
              Personally identifiable information may include, but is not
              limited to:
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              <span>- Email address</span>
              <br />
              <span>- First name and last name</span>
              <br />
              <span>- Address, State, Province, ZIP/Postal code, City </span>
              <br />
              <span>- Cookies and Usage Data</span>
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              Usage Data
            </p>
            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              We may also collect information how the Service is accessed and
              used (“Usage Data”). This Usage Data may include information such
              as your computer’s Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
              Tracking &amp; Cookies Data We use cookies and similar tracking
              technologies to track the activity on our Service and hold certain
              information. Cookies are files with small amount of data which may
              include an anonymous unique identifier. Cookies are sent to your
              browser from a website and stored on your device. Tracking
              technologies also used are beacons, tags, and scripts to collect
              and track information and to improve and analyze our Service. You
              can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              Examples of Cookies we use:
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              <span>
                - Session Cookies. We use Session Cookies to operate our
                Service.
              </span>
              <br />
              <span>
                - Preference Cookies. We use Preference Cookies to remember your
                preferences and various settings.
              </span>
              <br />
              <span>
                - Security Cookies. We use Security Cookies for security
                purposes.
              </span>
            </p>

            {/* // */}

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Use of Data?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              It’s All Fluff PTY LTD uses the collected data for various
              purposes:
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              <span>- To provide and maintain the Service</span>
              <br />
              <span>- To notify you about changes to our Service</span>
              <br />
              <span>
                - To allow you to participate in interactive features of our
                Service when you choose to do so
              </span>
              <br />
              <span>- To provide customer care and support</span>
              <br />
              <span>
                - To provide analysis or valuable information so that we can
                improve the Service
              </span>
              <br />
              <span>- To monitor the usage of the Service</span>
              <br />
              <span>- To detect, prevent and address technical issues</span>
            </p>

            {/* // */}

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Transfer Of Data?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              Your information, including Personal Data, may be transferred to —
              and maintained on — computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ than those from your jurisdiction.
              If you are located outside Australia and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to Australia and process it there. Your
              consent to this Privacy Policy followed by your submission of such
              information represents your agreement to that transfer. It’s All
              Fluff PTY LTD will take all steps reasonably necessary to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy and no transfer of your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of your data and other
              personal information.
            </p>

            {/* // */}

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Disclosure Of Data?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              Legal Requirements
            </p>
            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              It’s All Fluff PTY LTD may disclose your Personal Data in the good
              faith belief that such action is necessary to:
            </p>
            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              <span>- To comply with a legal obligation</span>
              <br />
              <span>
                - To protect and defend the rights or property of It’s All Fluff
                PTY LTD
              </span>
              <br />
              <span>
                - To prevent or investigate possible wrongdoing in connection
                with the Service
              </span>
              <br />
              <span>
                - To protect the personal safety of users of the Service or the
                public
              </span>
              <br />
              <span>- To protect against legal liability</span>
            </p>

            {/* // */}

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Security Of Data?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              The security of your data is important to us, but remember that no
              method of transmission over the Internet, or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>

            {/* // */}

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Service Providers?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              We may employ third party companies and individuals to facilitate
              our Service (“Service Providers”), to provide the Service on our
              behalf, to perform Service-related services or to assist us in
              analyzing how our Service is used.
            </p>
            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose. Analytics
            </p>
            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>
            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              <span>- Google Analytics</span>
              <br />
              <span>
                - Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network.
              </span>
              <br />
              <span>
                - You can opt-out of having made your activity on the Service
                available to Google Analytics by installing the Google Analytics
                opt-out browser add-on. The add-on prevents the Google Analytics
                JavaScript (ga.js, analytics.js, and dc.js) from sharing
                information with Google Analytics about visits activity.
              </span>
              <br />
              <span>
                - For more information on the privacy practices of Google,
                please visit the Google Privacy & Terms web page:
                https://policies.google.com/privacy?hl=en
              </span>
            </p>

            {/* // */}

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Links To Other Sites?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              Our Service may contain links to other sites that are not operated
              by us. If you click on a third party link, you will be directed to
              that third party’s site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>

            {/* // */}

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Changes To This Privacy Policy?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              “effective date” at the top of this Privacy Policy. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>

            {/* // */}

            <p className="w-3/4 xs:w-full mt-16 f3 font-medium text-black">
              Contact Us?
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              If you have any questions about this Privacy Policy, please
              contact us:
            </p>

            <p className="w-3/5 xs:w-full mt-4 b2 font-light text-black">
              <span>
                – by email:
                <a href="bunnies@itsallfluff.com"> bunnies@itsallfluff.com</a>
              </span>
            </p>

            {/* // */}
          </section>
        </Layout>
      </>
    );
  }
}

const PrivacyPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <PrivacyPageComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default PrivacyPage;
